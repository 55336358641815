var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import DataBox from "~/components/common/data-box.vue";
import { Prop, Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { NoticeService } from "~/services/manage-service/notice.service";
var NoticeBoard = /** @class */ (function (_super) {
    __extends(NoticeBoard, _super);
    function NoticeBoard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.indexSgin = "";
        _this.noteDataSet = [];
        return _this;
    }
    NoticeBoard.prototype.updateNotice = function (notice) { };
    NoticeBoard.prototype.mounted = function () {
        this.indexSgin = 0;
        this.refreshData();
    };
    NoticeBoard.prototype.refreshData = function () {
        var _this = this;
        // this.sortService.update("operatorTime","descending");
        this.loading.state = true;
        this.noticeService.getNoticeList(this.loading).subscribe(function (data) {
            _this.noteDataSet = data;
            // 更新列表
            _this.updateNotice(_this.noteDataSet && _this.noteDataSet.length ? _this.noteDataSet[0] : {});
        });
    };
    NoticeBoard.prototype.approvedAgreeMeth = function (item, index) {
        this.indexSgin = index;
        // 更新列表
        this.updateNotice(item);
    };
    __decorate([
        Dependencies(NoticeService)
    ], NoticeBoard.prototype, "noticeService", void 0);
    __decorate([
        Prop({
            default: ""
        })
    ], NoticeBoard.prototype, "notice", void 0);
    __decorate([
        Emit("update:notice")
    ], NoticeBoard.prototype, "updateNotice", null);
    NoticeBoard = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                SvgIcon: SvgIcon,
                DataBox: DataBox
            }
        })
    ], NoticeBoard);
    return NoticeBoard;
}(Vue));
export default NoticeBoard;
