var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { HomeNoticeService } from '~/services/dataimp-service/home-notice.service';
var collectionManageModule = namespace("collection-manage");
var ForeignVisits = /** @class */ (function (_super) {
    __extends(ForeignVisits, _super);
    function ForeignVisits() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            reminderTime: "",
            commentContent: ""
        };
        return _this;
    }
    ForeignVisits.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    ForeignVisits.prototype.emitRefeshData = function () { };
    ForeignVisits.prototype.refreshData = function () {
    };
    ForeignVisits.prototype.mounted = function () {
    };
    /**
     * 补款申请提交
     */
    ForeignVisits.prototype.commit = function () {
        var _this = this;
        var model = Object.assign({}, this.model);
        if (this.isAdd) {
            this.homeNoticeService.addRemark(model).subscribe(function (data) {
                _this.$message.success("添加备注成功");
                _this.close();
                _this.emitRefeshData();
            });
        }
        else {
            this.homeNoticeService.modifyRemark(model).subscribe(function (data) {
                _this.$message.success("编辑备注成功");
                _this.close();
                _this.emitRefeshData();
            });
        }
    };
    __decorate([
        Dependencies(HomeNoticeService)
    ], ForeignVisits.prototype, "homeNoticeService", void 0);
    __decorate([
        Prop()
    ], ForeignVisits.prototype, "currentAddress", void 0);
    __decorate([
        Prop()
    ], ForeignVisits.prototype, "isAdd", void 0);
    __decorate([
        Prop()
    ], ForeignVisits.prototype, "index", void 0);
    __decorate([
        Emit("close")
    ], ForeignVisits.prototype, "close", null);
    __decorate([
        Emit('refeshData')
    ], ForeignVisits.prototype, "emitRefeshData", null);
    ForeignVisits = __decorate([
        Component({
            components: {}
        })
    ], ForeignVisits);
    return ForeignVisits;
}(Vue));
export default ForeignVisits;
