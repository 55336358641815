var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import StarCollector from "~/components/dashboard/announcement-page/star-collector.vue";
import Todo from "~/components/dashboard/announcement-page/todo.vue";
import Notice from "~/components/dashboard/announcement-page/notice.vue";
import NoticeBoard from "~/components/dashboard/announcement-page/notice-board.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { State } from "vuex-class";
import { HomeNoticeService } from "~/services/dataimp-service/home-notice.service";
import Calendar from '~/components/dashboard/announcement-page/calendar.vue';
//@Auth(-1)
var DashboardBulletin = /** @class */ (function (_super) {
    __extends(DashboardBulletin, _super);
    function DashboardBulletin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.noticeInfo = {};
        return _this;
    }
    DashboardBulletin.prototype.mounted = function () {
    };
    /**
     *
     */
    DashboardBulletin.prototype.backToHome = function () {
        if (this.userData.isManager == "YES") {
            this.$router.push("/dashboard/dashboard-admin");
        }
        else {
            this.$router.push("/dashboard/dashboard-recruiter");
        }
    };
    __decorate([
        Dependencies(HomeNoticeService)
    ], DashboardBulletin.prototype, "homeNoticeService", void 0);
    __decorate([
        State
    ], DashboardBulletin.prototype, "userData", void 0);
    DashboardBulletin = __decorate([
        Layout("workspace"),
        Component({
            components: {
                StarCollector: StarCollector,
                Todo: Todo,
                Notice: Notice,
                NoticeBoard: NoticeBoard,
                Calendar: Calendar
            }
        })
    ], DashboardBulletin);
    return DashboardBulletin;
}(Vue));
export default DashboardBulletin;
