var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { reportService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
import { dashboardTimeType } from "~/config/enum.config";
import { CommonService } from "~/utils/common.service";
var HomePageService = /** @class */ (function () {
    function HomePageService() {
    }
    /**
     * 获取已还款案件数量和金额
     */
    HomePageService.prototype.getCaseAmtAndCount = function (data, loading) {
        // 修正日期
        var dateTime = CommonService.getDateTimeRange(data.date, data.timeType);
        return this.netService.send({
            server: reportService.homePageController.getCaseAmtAndCount,
            data: {
                timeType: data.timeType,
                queryType: data.queryType,
                startDate: dateTime.start,
                endDate: dateTime.end,
            },
            loading: loading,
        });
    };
    /**
     * 获取案件反馈信息
     * @param data
     */
    HomePageService.prototype.getCaseGroupInfo = function (data, loading) {
        // 修正日期
        var dateTime = CommonService.getDateTimeRange(data.date, data.timeType);
        return this.netService.send({
            server: reportService.homePageController.getCaseGroupInfo,
            data: {
                timeType: data.timeType,
                queryType: data.queryType,
                startDate: dateTime.start,
                endDate: dateTime.end,
            },
            loading: loading,
        });
    };
    /**
     * 管理员首页 根据年份查询该年度各月的调记，外呼数据量
     * @param data
     */
    HomePageService.prototype.getRecordReport = function (data, loading) {
        // 修正日期
        var dateTime = CommonService.getDateTimeRange(data.date, dashboardTimeType.month);
        return this.netService.send({
            server: reportService.homePageController.getRecordReport,
            data: {
                startDate: dateTime.start,
            },
            loading: loading,
        });
    };
    /**
     * 管理员首页 按省份获取调收数据
     * @param data
     */
    HomePageService.prototype.getCollectionedDate = function (loading) {
        return this.netService.send({
            server: reportService.homePageController.getCollectionedDate,
            loading: loading,
        });
    };
    /**
     * 管理员首页 内调汇款排行榜
     * @param data
     */
    HomePageService.prototype.getCollectorRanking = function (data, page, loading) {
        return this.netService.send({
            server: reportService.homePageController.collectorRanking,
            data: {
                rankType: data.rankType,
                timeType: data.timeType,
            },
            page: page,
            loading: loading,
        });
    };
    /**
     * 管理员首页 委外汇款排行榜
     * @param data
     */
    HomePageService.prototype.getOutsourceRanking = function (data, page, loading) {
        return this.netService.send({
            server: reportService.homePageController.outsourceRanking,
            data: {
                rankType: data.rankType,
                timeType: data.timeType,
            },
            page: page,
            loading: loading,
        });
    };
    /**
     * 管理员首页 获取调收进度数
     * @param data
     */
    HomePageService.prototype.getCollectedCount = function (loading) {
        return this.netService.send({
            server: reportService.homePageController.getHomePageCollectedPage,
            loading: loading,
        });
    };
    /**
     * 获取调记数量
     */
    HomePageService.prototype.getFollowCountInfo = function (loading) {
        return this.netService.send({
            server: reportService.homePageController.getHomePagePreviewTotalFollow,
            loading: loading,
        });
    };
    /**
     * 获取案件状态数
     */
    HomePageService.prototype.getCaseStatusInfo = function (loading) {
        return this.netService.send({
            server: reportService.homePageController.getHomePageCaseFollowedPreview,
            loading: loading,
        });
    };
    /**
     * 统计调收员首页回款金额排名
     */
    HomePageService.prototype.getCollectedCaseBackRank = function (_a, loading) {
        var timeType = _a.timeType;
        return this.netService.send({
            server: reportService.homePageController.getHomePageCollectedCaseBackRank,
            data: {
                timeType: timeType,
            },
            loading: loading,
        });
    };
    /**
     * 统计调收员首页跟调量排名
     */
    HomePageService.prototype.getCollectedFollowedRank = function (_a, loading) {
        var timeType = _a.timeType;
        return this.netService.send({
            server: reportService.homePageController.getHomePageCollectedFollowedRank,
            data: {
                timeType: timeType,
            },
            loading: loading,
        });
    };
    /**
     * 获取快速调收信息
     */
    HomePageService.prototype.getQuickAccessCaseInfo = function (loading) {
        return this.netService.send({
            server: reportService.homePageController.quickAccessCaseInfo,
            loading: loading,
        });
    };
    /**
     * 公告 明星调收员
     */
    HomePageService.prototype.getStarUser = function (loading) {
        return this.netService.send({
            server: reportService.homePageController.getStarUser,
            loading: loading,
        });
    };
    /**
     * 公告 明星委托方
     */
    HomePageService.prototype.getPrincipal = function (loading) {
        return this.netService.send({
            server: reportService.homePageController.getPrincipal,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], HomePageService.prototype, "netService", void 0);
    return HomePageService;
}());
export { HomePageService };
