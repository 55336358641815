import { render, staticRenderFns } from "./promise-repayment.vue?vue&type=template&id=28c1dd1f&scoped=true"
import script from "./promise-repayment.vue?vue&type=script&lang=ts"
export * from "./promise-repayment.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28c1dd1f",
  null
  
)

export default component.exports