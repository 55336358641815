var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import OuterCase from "~/components/dashboard/announcement-page/dia-log/outer-case.vue";
import Information from "~/components/dashboard/announcement-page/dia-log/information.vue";
import BbsInformation from "~/components/dashboard/announcement-page/dia-log/bbs-information.vue";
import PromiseRepayment from "~/components/dashboard/announcement-page/dia-log/promise-repayment.vue";
import AlreadyRepayment from "~/components/dashboard/announcement-page/dia-log/already-repayment.vue";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { HomeNoticeService } from "~/services/dataimp-service/home-notice.service";
var Todo = /** @class */ (function (_super) {
    __extends(Todo, _super);
    function Todo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dialog = {
            promiseRepayment: false,
            alreadyRepayment: false,
            outerCase: false,
            information: false,
            bbsInformation: false
        };
        _this.waitHandModel = {};
        return _this;
    }
    Todo.prototype.mounted = function () {
        this.refreshData();
    };
    Todo.prototype.refreshData = function () {
        var _this = this;
        // 明星调解员
        this.homeNoticeService.getWaitHandleList().subscribe(function (data) {
            _this.waitHandModel = data;
        });
    };
    __decorate([
        Dependencies(HomeNoticeService)
    ], Todo.prototype, "homeNoticeService", void 0);
    Todo = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                PromiseRepayment: PromiseRepayment,
                AlreadyRepayment: AlreadyRepayment,
                OuterCase: OuterCase,
                Information: Information,
                BbsInformation: BbsInformation
            }
        })
    ], Todo);
    return Todo;
}(Vue));
export default Todo;
