var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { HomeNoticeService } from "~/services/dataimp-service/home-notice.service";
import { HomePageService } from '~/services/report-service/home-page.service';
var StarCollector = /** @class */ (function (_super) {
    __extends(StarCollector, _super);
    function StarCollector() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 明星调解员
        _this.collectorDataSet = {
            currentStarUser: '',
            currentPayment: 0,
            lastStarUser: '',
            lastPayment: 0
        };
        // 明星调解部门
        _this.organizationDataSet = {
            currentPrincipalName: '',
            currentPayment: 0,
            lastPrincipalName: '',
            lastPayment: 0
        };
        _this.loading = {
            state: false
        };
        return _this;
    }
    StarCollector.prototype.created = function () { };
    StarCollector.prototype.mounted = function () {
        this.refreshData();
    };
    StarCollector.prototype.refreshData = function () {
        this.loading.state = true;
        //    // 明星调解员
        //    this.homePageService.getStarUser(this.loading).subscribe(data => {
        //      this.collectorDataSet = data
        //    });
        //
        //    // 明星调解部门
        //    this.homePageService.getPrincipal(this.loading).subscribe(data => {
        //      this.organizationDataSet = data
        //    });
    };
    __decorate([
        Dependencies(HomeNoticeService)
    ], StarCollector.prototype, "homeNoticeService", void 0);
    __decorate([
        Dependencies(HomePageService)
    ], StarCollector.prototype, "homePageService", void 0);
    StarCollector = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                SvgIcon: SvgIcon,
                DataBox: DataBox
            }
        })
    ], StarCollector);
    return StarCollector;
}(Vue));
export default StarCollector;
